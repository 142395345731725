:root {
  --main-background: white;
  --main-color: black;
  --sidebar-background: #e3e3e3;
  --expander-background: #b5b5b5;
  --expander-background-hover: #a6a6a6;
  --expander-border: #6c6c6c;
  --button-color: #eff7ef;
  --button-background: #545499;
  --button-background-hover: #00007c;
  --button-border: #e3e3e3;
  --danger-button-color: #eff7ef;
  --danger-button-background: #db2626;
  --danger-button-background-hover: #b20000;
  --danger-button-border: #e3e3e3;
  --green: green;
  --gray: #4a4a4a;
}

@media (prefers-color-scheme: dark) {
  :root {
    --main-background: #212529;
    --main-color: #f8f9fa;
    --sidebar-background: #131618;
    --expander-background: #424446;
    --expander-background-hover: #2a2d2f;
    --button-background: #282879;
    --button-background-hover: #00007c;
    --button-border: #131618;
    --danger-button-background: #b20000;
    --danger-button-background-hover: red;
    --danger-button-border: #131618;
    --green: #53b266;
    --gray: #979797;
    --red: #fb615b;
  }
}

html {
  scrollbar-color: #8e8e8e transparent;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  grid-template-rows: auto 1fr max-content;
  grid-template-columns: max-content 1fr max-content;
  font-family: monospace;
  display: grid;
}

noscript {
  max-width: 600px;
  grid-column-start: 2;
  margin: 0 auto;
  display: block;
}

button {
  background: var(--button-background);
  color: var(--button-color);
  border-style: solid;
  border-color: var(--button-border);
  cursor: pointer;
  border-width: 1px;
  padding: .25rem .5rem;
  font-size: .8rem;
  transition: background .25s linear;
}

button:hover {
  background: var(--button-background-hover);
}

button.danger {
  background: var(--danger-button-background);
  color: var(--danger-button-color);
  border-color: var(--danger-button-border);
}

button.danger:hover {
  background: var(--danger-button-background-hover);
}

#buffer, #connect {
  color: var(--main-color);
  background: var(--main-background);
  width: 100%;
  height: 100%;
  overflow: hidden auto;
}

#buffer-list, #member-list {
  color: var(--main-color);
  background: var(--main-background);
  width: 100%;
  height: 100%;
  background-color: var(--sidebar-background);
  flex-direction: row;
  grid-area: 1 / 1 / 4;
  display: flex;
}

.expander {
  cursor: pointer;
  background: var(--expander-background);
  width: 10px;
  border: none;
  margin: 0;
  padding: 0;
  transition: background .2s linear;
  display: none;
}

.expander:hover {
  background: var(--expander-background-hover);
}

.expander span {
  width: 100%;
  height: 2px;
  border-style: solid;
  border-width: 1px 0;
  border-color: var(--expander-border);
  margin-bottom: 2px;
  display: block;
}

#buffer-list ul {
  scrollbar-width: thin;
  flex: 1 0 auto;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden auto;
}

#buffer-list li a {
  width: 100%;
  box-sizing: border-box;
  padding: 2px 10px;
  display: inline-block;
}

#buffer-list li.active a {
  color: #fff;
  background-color: var(--gray);
}

#buffer-list li.error a {
  color: red;
}

#buffer-list li.unread-message a {
  color: #b37400;
}

#buffer-list li.unread-highlight a {
  color: #22009b;
}

#buffer-list li:not(.type-server) a {
  padding-left: 20px;
}

#member-list-header {
  color: var(--main-color);
  background-color: var(--main-background);
  box-sizing: border-box;
  border-bottom: 1px solid var(--sidebar-background);
  padding: 5px 10px;
}

#buffer-header {
  color: var(--main-color);
  background-color: var(--main-background);
  box-sizing: border-box;
  border-bottom: 1px solid var(--sidebar-background);
  grid-area: 1 / 2;
  grid-template-rows: 1fr auto;
  grid-template-columns: 1fr auto;
  display: grid;
}

#buffer-header .title {
  grid-area: 1 / 1;
  padding: 5px 10px;
  font-weight: bold;
  display: none;
}

#buffer-header .description {
  max-height: 20vh;
  word-break: break-word;
  grid-area: 2 / 1;
  padding: 5px 10px;
  overflow-y: auto;
}

#buffer-header .actions {
  grid-area: 1 / 2 / 3;
}

#buffer-header .status-here {
  color: var(--green);
}

#buffer-header .status-gone {
  color: orange;
}

#buffer-header .status-offline {
  color: red;
}

.actions {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.actions button {
  border-width: 0 0 0 1px;
}

#buffer {
  grid-area: 2 / 2;
}

#buffer:focus {
  outline: none;
}

#member-list {
  width: 100%;
  height: 100%;
  flex-direction: row;
  grid-area: 1 / 3 / 4;
  display: flex;
}

#member-list > section {
  flex-direction: column;
  display: flex;
}

#member-list-header {
  border-left: 1px solid var(--sidebar-background);
}

@media (max-width: 640px) {
  #buffer-list ul {
    width: 0;
  }

  #buffer-list.expand {
    z-index: 999;
    grid-column: 1 / 4;
  }

  #buffer-list.expand ul {
    width: auto;
  }
}

#member-list ul {
  scrollbar-width: thin;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow: hidden auto;
}

#member-list li a {
  width: 100%;
  box-sizing: border-box;
  padding: 2px 10px;
  display: inline-block;
}

#member-list li a.away {
  color: var(--gray);
}

.membership.owner {
  color: red;
}

.membership.admin {
  color: #00f;
}

.membership.op {
  color: var(--green);
}

.membership.halfop {
  color: orange;
}

.membership.voice {
  color: #ff0;
}

#composer {
  color: var(--main-color);
  background: var(--main-background);
  border-top: 1px solid var(--sidebar-background);
  grid-area: 3 / 2 / auto / 3;
}

#composer input {
  height: 35px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  display: block;
}

#composer input, #composer input:focus {
  background: none;
  border: none;
  outline: none;
}

#composer.read-only {
  display: none;
}

#connect {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#connect form {
  max-width: 350px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 10px;
}

form input[type="text"], form input[type="username"], form input[type="password"], form input[type="url"], form input[type="email"] {
  box-sizing: border-box;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
}

a {
  color: var(--green);
}

#buffer-list li a, a.timestamp, a.nick {
  color: var(--gray);
  text-decoration: none;
}

#buffer-list li a:hover, #buffer-list li a:active, a.timestamp:hover, a.timestamp:active, a.nick:hover, a.nick:active {
  text-decoration: underline;
}

details summary[role="button"] {
  user-select: none;
  cursor: pointer;
}

#buffer {
  box-sizing: border-box;
  color: var(--gray);
  word-wrap: break-word;
}

#buffer .logline-list {
  padding: 10px;
}

#buffer .logline {
  white-space: pre-wrap;
}

#buffer .talk, #buffer .motd {
  color: var(--main-color);
}

#buffer .error {
  color: red;
}

#buffer .me-tell {
  color: #b37400;
}

#buffer .highlight .nick {
  color: #ff0;
  background-color: #c700ff;
}

#buffer .nick-1 {
  color: #f25e0d;
}

#buffer .nick-2 {
  color: #e43611;
}

#buffer .nick-3 {
  color: #f98a11;
}

#buffer .nick-4 {
  color: #b4a700;
}

#buffer .nick-5 {
  color: #89bd3b;
}

#buffer .nick-6 {
  color: #4ea847;
}

#buffer .nick-7 {
  color: #287e52;
}

#buffer .nick-8 {
  color: #117873;
}

#buffer .nick-9 {
  color: #0083a7;
}

#buffer .nick-10 {
  color: #2a6596;
}

#buffer .nick-11 {
  color: #385189;
}

#buffer .nick-12 {
  color: #434078;
}

#buffer .nick-13 {
  color: #5e4279;
}

#buffer .nick-14 {
  color: #7a447a;
}

#buffer .nick-15 {
  color: #e92980;
}

#buffer .nick-16 {
  color: #ec273e;
}

#buffer .separator {
  text-align: center;
  text-transform: lowercase;
  font-variant: small-caps;
  align-items: center;
  display: flex;
}

#buffer .separator:before, #buffer .separator:after {
  content: "";
  border-bottom: 1px solid #0000;
  flex: 1;
}

#buffer .date-separator {
  color: #757575;
}

#buffer .date-separator:before, #buffer .date-separator:after {
  border-color: #ddd;
}

#buffer .unread-separator {
  color: #ff3535;
}

#buffer .unread-separator:before, #buffer .unread-separator:after {
  border-color: #ff3535;
}

#error-msg {
  color: #fff;
  background-color: red;
  margin: .5rem;
  padding: .5rem;
  position: fixed;
  bottom: 2rem;
  right: 0;
}

#error-msg button, .dialog button.dialog-close {
  color: inherit;
  font-size: inherit;
  background: none;
  border: none;
  padding: 0;
}

.error-text {
  color: red;
}

.dialog {
  background-color: #00000080;
  position: fixed;
  inset: 0;
  overflow: auto;
}

.dialog .dialog-body {
  background-color: var(--main-background);
  color: var(--main-color);
  max-width: 500px;
  border: 1px solid #0009;
  margin: 20px auto;
  padding: 15px;
}

.dialog .dialog-header {
  flex-direction: row;
  align-items: center;
  margin-bottom: 1em;
  display: flex;
}

.dialog .dialog-header h2 {
  flex-grow: 1;
  margin: 0;
}

.dialog .dialog-header button.dialog-close {
  padding: 0 5px;
  font-size: 1.5em;
}

.dialog h2 {
  margin-top: 0;
}

.dialog form button:not([type="button"]) {
  float: right;
}

.dialog form {
  overflow: auto;
}

.dialog .protocol-handler {
  flex-direction: row;
  display: flex;
}

.dialog .protocol-handler .left {
  flex-grow: 1;
}

kbd {
  min-width: 1em;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f0f0;
  border: 1px solid #bfbfbf;
  border-radius: 3px;
  margin: 3px;
  padding: 2px 4px;
  font-family: monospace;
  font-size: 80%;
  display: inline-block;
  box-shadow: inset 0 1px #fff, inset 0 -2px #d9d9d9;
}

@media (prefers-color-scheme: dark) {
  html {
    scrollbar-color: var(--gray) transparent;
  }

  #buffer-list li.active a {
    color: var(--sidebar-background);
    background-color: #fff;
  }

  #buffer-list li.unread-message a {
    color: var(--green);
  }

  #buffer-list li.unread-highlight a {
    color: #0062cc;
  }

  #buffer-header .status-gone {
    color: #fb885b;
  }

  #buffer-header .status-offline {
    color: var(--red);
  }

  form input[type="text"], form input[type="username"], form input[type="password"], form input[type="url"], form input[type="email"] {
    color: #fff;
    background: var(--sidebar-background);
    border: 1px solid #495057;
    padding: .25rem .375rem;
  }

  form input[type="text"]:focus, form input[type="username"]:focus, form input[type="password"]:focus, form input[type="url"]:focus, form input[type="email"]:focus {
    border-color: #3897ff;
    outline: 0;
  }

  #buffer-list li a, a.nick {
    color: var(--main-color);
  }

  #buffer {
    background: var(--main-background);
  }

  #buffer .error {
    color: var(--red);
  }

  #buffer .me-tell {
    color: #e16b97;
  }

  #buffer .highlight .nick {
    color: #1e0010;
    background-color: #0062cc;
  }

  #buffer .date-separator {
    color: #757575;
  }

  #buffer .date-separator:before, #buffer .date-separator:after {
    border-color: #373737;
  }

  #buffer .unread-separator {
    color: var(--green);
  }

  #buffer .unread-separator:before, #buffer .unread-separator:after {
    border-color: var(--green);
  }

  #error-msg {
    color: #000;
    background-color: #efa3aa;
  }

  #error-msg a {
    color: #000;
  }

  .error-text {
    color: #efa3aa;
  }

  #buffer .nick-8 {
    color: #6bccc7;
  }

  #buffer .nick-10 {
    color: #6296c1;
  }

  #buffer .nick-11 {
    color: #c6b5df;
  }

  #buffer .nick-12 {
    color: #9995df;
  }

  #buffer .nick-13 {
    color: #8b62b3;
  }

  #buffer .nick-15 {
    color: #e3418b;
  }

  kbd {
    --outline-color: #6e7681;
    border: 1px solid var(--outline-color);
    box-shadow: inset 0 -1px 0 var(--outline-color);
    background-color: #0000;
  }
}

@media (max-width: 640px) {
  .expander {
    display: block;
  }

  #buffer-list ul, #member-list > section {
    width: 0;
  }

  #buffer-list.expand, #member-list.expand {
    z-index: 999;
    grid-column: 1 / 4;
  }

  #buffer-list.expand ul {
    width: auto;
  }

  #member-list.expand > section {
    width: 100%;
  }

  #member-list-header {
    border-left: none;
  }

  #buffer-header {
    grid-template-columns: 1fr min-content;
  }

  #buffer-header .title {
    display: block;
  }
}

@media (pointer: none), (pointer: coarse) {
  #buffer-list li a, #member-list li a {
    padding: 7px 10px;
  }
}

/*# sourceMappingURL=index.3d2f98ec.css.map */
